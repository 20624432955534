import { GlobalLoading } from '~/components/global-loading';

export type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps) {
  return (
    <main>
      <GlobalLoading />
      {children}
    </main>
  );
}
