import { LinksFunction, LoaderFunctionArgs, json } from '@remix-run/cloudflare';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';
import '~/global-styles/tailwind.css';
import { Layout as RootLayout } from './layouts/root-layout';
import i18nextServer from './localization/i18next.server';
import { UnhandledError } from './page-section/unhandled-error-page';

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossOrigin: 'anonymous' },
  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Bellefair&display=swap' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap',
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const locale = await i18nextServer.getLocale(request);
  return json({ locale });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const { i18n } = useTranslation();
  const locale = data?.locale ?? 'en';
  useChangeLanguage(locale);
  const shouldRenderGA = import.meta.env.PROD;
  const gtagId = 'G-BYD43VYLDD';

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {shouldRenderGA && <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`} />}
        {shouldRenderGA && (
          <script
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtagId}');
        `,
            }}
          />
        )}
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <UnhandledError title={error.statusText} statusCode={error.status} description={JSON.stringify(error.data)} />
    );
  } else if (error instanceof Error) {
    return <UnhandledError title="Something unexpected happened" statusCode={500} description={error.message} />;
  } else {
    return (
      <UnhandledError title="Something unexpected happened" statusCode={500} description={JSON.stringify(error)} />
    );
  }
};

export default function App() {
  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
}
